import { MovieListItem } from '#/shared/movie-list/MovieListItem';
import { findIndex } from '#/shared/utils/array';

export interface MovieList {
  readonly items: ReadonlyArray<MovieListItem>;

  readonly current: number;
}

export namespace MovieList {
  export function dummy(): MovieList {
    return {
      items: [
        {
          id: 1,
          thumbnailUrl: './image/thumbnails/streaming-1.jpg',
          dashUrl: 'https://demo-ott.multicdnet.net/dash/stream/manifest.mpd',
          hlsUrl: 'https://demo-ott.multicdnet.net/dash/stream/playlist.m3u8',
          title: 'ミハル通信 ライブカメラ',
          description: 'ミハル通信ブース内に設置したライブカメラの映像をお送りいたします。',
        },
        {
          id: 2,
          thumbnailUrl: './image/thumbnails/streaming-2.jpg',
          dashUrl: 'https://demo-ott.multicdnet.net/dash/stream2/manifest.mpd',
          hlsUrl: 'https://demo-ott.multicdnet.net/dash/stream2/playlist.m3u8',
          title: '古河電気工業 ライブカメラ',
          description: '古河電気工業ブース内に設置したライブカメラの映像をお送りいたします。',
        },
        {
          id: 3,
          thumbnailUrl: './image/thumbnails/test-obs.jpg',
          dashUrl: 'https://demo-ott.multicdnet.net/obs/rtmp/manifest.mpd', // 'https://d188kz72cnjfle.cloudfront.net/out/v1/9654d4372252487691a877b739066c47/index.mpd', // 'http://192.168.114.100:8080/live/livestream.mpd', // 'https://d188kz72cnjfle.cloudfront.net/out/v1/9654d4372252487691a877b739066c47/index.mpd',
          hlsUrl: 'https://iqs.multicdnet.com/srt/test/playlist.m3u8',
          title: 'ライブストリーミング映像',
          description:
            '鎌倉工場からのライブストリーミングが視聴できます。工場内に設置された鎌倉の模型の様子がライブ配信されます。',
        },
        {
          id: 4,
          thumbnailUrl: './image/thumbnails/test-local.jpg',
          dashUrl: 'http://192.168.114.100:8080/live/livestream.mpd', // 'https://iqs.multicdnet.com/home/demo/vod/carview_8Mbps_2.mp4/manifest.mpd',
          hlsUrl: 'https://iqs.multicdnet.com/home/demo/vod/carview_8Mbps_2.mp4/playlist.m3u8',
          title: '箱根ドライブ 前面展望',
          description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
        },
      ],
      current: 1,
    };
  }

  export function moveLeft(list: MovieList): MovieList {
    const i = findIndex(list.items, (item) => item.id === list.current);

    if (i < 1) {
      return { ...list, current: list.items[0].id };
    }

    return { ...list, current: list.items[i - 1].id };
  }

  export function moveRight(list: MovieList): MovieList {
    const i = findIndex(list.items, (item) => item.id === list.current);

    if (list.items.length - 1 <= i) {
      return { ...list, current: list.items[list.items.length - 1].id };
    }

    return { ...list, current: list.items[i + 1].id };
  }

  export function currentItem(list: MovieList): MovieListItem {
    const i = findIndex(list.items, (item) => item.id === list.current);

    return list.items[i];
  }

  export function select(list: MovieList, id: number): MovieList {
    const index = findIndex(list.items, (item) => item.id === id);

    if (index < 0) {
      return { ...list, current: 0 };
    } else {
      return { ...list, current: index };
    }
  }

  export function selected(list: MovieList, id: number): boolean {
    const index = findIndex(list.items, (item) => item.id === id);

    return list.current === index;
  }
}
