export function findIndex<A>(array: ReadonlyArray<A>, predicate: (value: A, index: number) => boolean): number {
  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    if (predicate(item, i)) {
      return i;
    }
  }

  return -1;
}
