import { UAParser } from 'ua-parser-js';

function getIsTouchDevice(userAgent: string): boolean {
  const parser = new UAParser(userAgent);
  const device = parser.getDevice();

  if (device.type === 'mobile' || device.type === 'tablet') {
    return true;
  }

  if ('ontouchend' in document) {
    return true;
  }

  return false;
}

function getUseMpegDash(userAgent: string): boolean {
  const parser = new UAParser(userAgent);
  const os = parser.getOS();
  const browser = parser.getBrowser();

  if (os.name === 'iOS') {
    return false;
  }

  if (os.name === 'Mac OS' && browser.name === 'Safari') {
    return false;
  }

  return true;
}

export const useMpegDash = getUseMpegDash(window.navigator.userAgent);

export const isTouchDevice = getIsTouchDevice(window.navigator.userAgent);
