import { useMpegDash } from '#/utils/browser';
import { WebKitHTMLVideoElement } from '#/shared/video/webkit';
import { exitFullScreen, requestFullScreen } from '#/shared/video/full-screen';
import { appendLog } from '#/shared/utils/logging';

let player: DashTVPlayer | undefined;
let playing: boolean = false;

function handleEnded(this: HTMLVideoElement, ev: Event) {
  console.log('ended', ev);

  stopMovie();

  // フルスクリーン表示を解除する必要がある
  exitFullScreen();
}

function handleFullScreenChange(this: HTMLVideoElement, ev: Event) {
  console.log('fullscreenchange', ev);

  if (!document.fullscreenElement) {
    stopMovie();
  }
}

function handleFullScreenError(this: HTMLVideoElement, ev: Event) {
  console.error('fullscreenerror', ev);
}

function handleWebKitEndFullScreen(this: WebKitHTMLVideoElement, ev: Event) {
  console.log('webkitendfullscreen', ev);

  stopMovie();
}

export function startMovie(dashUrl: string, hlsUrl: string): void {
  if (playing) {
    return;
  }

  playing = true;

  const area = document.getElementById('video-area')! as HTMLDivElement;
  area.classList.remove('hidden');

  if (useMpegDash) {
    const video = area.querySelector('video')!;

    player = new DashTVPlayer(video);
    const src: UrlType = {
      type: 'url',
      source: dashUrl,
      params: {
        DEFAULT_MIN_BUFFER_TIME: 2,
        FORCE_DEFAULT_MBT: false,
        MSE_APPEND_ENABLE_THRESHOLD: 5,
        BUFFER_PREFETCH_THRESHOLD: 15,
        LOADING_REQUEST_THRESHOLD: 2,
        DEFAULT_MANIFEST_REFRESH_DELAY: 10,
        //DEFAULT_PRESENTATION_DELAY: 2,
        //ADJUST_TIMESTAMP_OFFSET : false,
        //START_FROM_MPDTOP_FORLIVE: true,
        //SET_1STSEG_TIME_ZERO: false,
        //MIN_SEGSIZE_FORBASE: 2,
        //EXTRACT_ALL_IDR_IN_MOOF: true,
        //LISTEN_TO_CANPLAY_AFTER_SEEK: true,
        USE_FETCH: true,
        DELETE_PAST_DASHEVENT: true,
        DELETE_UNNECESSARY_BOX: true,
        //UNUSE_AUDIO: true,
        //ULL_MODE: true,
        BDAT_INSERT_MODE: false,

        // ココからカスタム
        SKIP_GAP_AT_HOB: true,
      },
    };

    player.setAutoPlay(true);
    player.attachSource(src, NaN, NaN);

    video.removeEventListener('ended', handleEnded);
    video.addEventListener('ended', handleEnded);

    video.removeEventListener('fullscreenchange', handleFullScreenChange);
    video.addEventListener('fullscreenchange', handleFullScreenChange);

    video.removeEventListener('fullscreenerror', handleFullScreenError);
    video.addEventListener('fullscreenerror', handleFullScreenError);

    requestFullScreen(video);
  } else {
    const video = area.querySelector('video')! as WebKitHTMLVideoElement;
    video.setAttribute('src', hlsUrl);

    video.load();
    video.addEventListener('canplay', async (ev) => {
      video.play();

      await requestFullScreen(video);
    });

    video.removeEventListener('webkitendfullscreen', handleWebKitEndFullScreen);
    video.addEventListener('webkitendfullscreen', handleWebKitEndFullScreen);
  }
}

export function stopMovie(): void {
  if (!playing) {
    return;
  }

  playing = false;

  const area = document.getElementById('video-area')! as HTMLDivElement;
  area.classList.add('hidden');

  if (useMpegDash) {
    if (player) {
      player.setPause(true);
      player.end();
      player.reset();
      player = undefined;

      const video = area.querySelector('video')!;
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('fullscreenchange', handleFullScreenChange);
    }
  } else {
    const video = area.querySelector('video')! as HTMLVideoElement;
    video.pause();
    video.removeEventListener('webkitendfullscreen', handleWebKitEndFullScreen);
    video.removeAttribute('src');
  }
}
