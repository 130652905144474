import { forEach } from '#/shared/utils/dom';

const panels = {
  movie: document.querySelectorAll('.movie-panel'),
  innoQos: document.querySelectorAll('.inno-qos-panel'),
  system: document.querySelectorAll('.system-panel'),
  info: document.querySelectorAll('.info-panel'),
};

function show(nodes: NodeListOf<Element>): void {
  forEach(nodes, (elem) => {
    elem.classList.remove('hidden');
  });
}

function hide(nodes: NodeListOf<Element>): void {
  forEach(nodes, (elem) => {
    elem.classList.add('hidden');
  });
}

export function showMoviePanel(): void {
  show(panels.movie);
  hide(panels.innoQos);
  hide(panels.system);
  hide(panels.info);
}

export function showInnoQosPanel(): void {
  hide(panels.movie);
  show(panels.innoQos);
  hide(panels.system);
  hide(panels.info);
}

export function showSystemPanel(): void {
  hide(panels.movie);
  hide(panels.innoQos);
  show(panels.system);
  hide(panels.info);
}

export function showInfoPanel(): void {
  hide(panels.movie);
  hide(panels.innoQos);
  hide(panels.system);
  show(panels.info);
}
