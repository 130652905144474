import { WebKitHTMLVideoElement } from '#/shared/video/webkit';

export async function requestFullScreen(elem: HTMLVideoElement): Promise<void> {
  if (elem.requestFullscreen) {
    try {
      await elem.requestFullscreen();
    } catch (ex) {
      console.error(ex);
    }

    return;
  }

  const e = elem as WebKitHTMLVideoElement;
  if (e.webkitRequestFullscreen) {
    try {
      await e.webkitRequestFullscreen();
    } catch (ex) {
      console.error(ex);
    }

    return;
  }
}

export function exitFullScreen() {
  if (!document.fullscreenElement) {
    return;
  }

  document.exitFullscreen().catch((reason) => {
    console.error(reason);
  });
}
