export function appendLog(message: string): void {
  const pre = document.getElementById('logger-area') as HTMLParagraphElement;

  if (pre) {
    const span = document.createElement('span');
    span.innerText = message;

    if (pre.children.length !== 0) {
      pre.appendChild(document.createElement('br'));
    }

    pre.appendChild(span);

    while (pre.firstChild && pre.children.length > 1000) {
      pre.removeChild(pre.firstChild);
    }
  }
}

let prev: number = new Date().getTime();
let greenCount = 0;
let isDebugMode = true;

export function pressGreen(): void {
  const now = new Date().getTime();

  if (now - prev > 500) {
    greenCount = 1;
  } else {
    greenCount += 1;
  }

  prev = now;

  if (greenCount < 3) {
    return;
  }

  isDebugMode = !isDebugMode;
  const pre = document.getElementById('logger-area');

  if (pre) {
    if (isDebugMode) {
      pre.classList.remove('hidden');
    } else {
      pre.classList.add('hidden');
    }
  }
}

// @ts-ignore
window.appendLog = appendLog;
