import 'core-js/actual/array';
import 'core-js/actual/map';
import 'core-js/actual/url';
// import 'core-js/actual/array-buffer';
import 'core-js/actual/string';

import { showInfoPanel, showInnoQosPanel, showMoviePanel, showSystemPanel } from '#/shared/layout';
import { MovieList } from '#/shared/movie-list/MovieList';
import { MovieListViewer } from '#/shared/movie-list/MovieListViewer';
import { appendLog, pressGreen } from '#/shared/utils/logging';

const list = MovieList.dummy();
const viewer = new MovieListViewer(list);

document.getElementById('show-movie-panel')?.addEventListener('click', (e) => {
  e.preventDefault();

  showMoviePanel();
});
document.getElementById('show-inno-qos-panel')?.addEventListener('click', (e) => {
  e.preventDefault();

  showInnoQosPanel();
});
document.getElementById('show-system-panel')?.addEventListener('click', (e) => {
  e.preventDefault();

  showSystemPanel();
});
document.getElementById('show-info-panel')?.addEventListener('click', (e) => {
  e.preventDefault();

  showInfoPanel();
});

window.addEventListener('keydown', (e) => {
  switch (e.which) {
    case VK_BLUE:
      showMoviePanel();
      break;
    case VK_RED:
      showInnoQosPanel();
      break;
    case VK_GREEN:
      showSystemPanel();
      pressGreen();
      break;
    case VK_YELLOW:
      showInfoPanel();
      break;
    case VK_BACK:
      viewer.stop();
      break;
    case VK_ENTER:
      viewer.start();
      break;
    case VK_LEFT:
      viewer.selectLeft();
      break;
    case VK_RIGHT:
      viewer.selectRight();
      break;
    case 27:
      // ESC
      viewer.stop();
      break;
    case VK_DBUTTON:
      location.reload();
      break;
    default:
      break;
  }
});

DashTVPlayer.LogHandler.log = (str, color) => {
  appendLog('[H-LOG]' + str);
};

DashTVPlayer.Debug.log = (msg) => {
  appendLog('[LOG  ] ' + msg);
};
DashTVPlayer.Debug.debug = (msg) => {
  appendLog('[DEBUG] ' + msg);
};
DashTVPlayer.Debug.info = (msg) => {
  appendLog('[INFO ] ' + msg);
};
DashTVPlayer.Debug.warn = (msg) => {
  appendLog('[WARN ] ' + msg);
};
DashTVPlayer.Debug.error = (msg) => {
  appendLog('[ERROR] ' + msg);
};
