export function forEach<TNode extends Node>(
  nodes: NodeListOf<TNode>,
  callbackfn: (value: TNode, key: number) => void
): void {
  const length = nodes.length;

  for (let i = 0; i < length; i++) {
    const item = nodes[i];
    callbackfn(item, i);
  }
}

export function addClass(elem: HTMLElement, name: string): void {
  elem.className += ' ' + name;
}
