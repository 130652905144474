import { MovieList } from '#/shared/movie-list/MovieList';
import { MovieListItem } from '#/shared/movie-list/MovieListItem';
import { forEach } from '#/shared/utils/dom';
import { startMovie, stopMovie } from '#/shared/video';

export interface IMovieListViewer {
  enter(): void;
  leave(): void;
  selectLeft(): void;
  selectRight(): void;

  start(): void;
  stop(): void;
}

export class MovieListViewer implements IMovieListViewer {
  private show: boolean = false;
  private list: MovieList;
  private selected: MovieListItem;

  constructor(list: MovieList) {
    this.selected = MovieList.currentItem(list);
    this.list = list;

    const panel = document.getElementById('row-movie-thumbnail')! as HTMLDivElement;
    forEach(panel.querySelectorAll<HTMLDivElement>('.col-md-3'), (col, i) => {
      this.applyThumbnailElement(col, list.items[i]);
    });

    this.selectMovieListItem(MovieList.currentItem(list));

    const button = document.getElementById('play-movie-button')! as HTMLButtonElement;
    button.addEventListener('click', (e) => {
      e.preventDefault();
      this.start();
    });
  }

  public enter(): void {}
  public leave(): void {}

  public selectLeft(): void {
    this.list = MovieList.moveLeft(this.list);
    this.selectMovieListItem(MovieList.currentItem(this.list));
  }

  public selectRight(): void {
    this.list = MovieList.moveRight(this.list);
    this.selectMovieListItem(MovieList.currentItem(this.list));
  }

  public start(): void {
    startMovie(this.selected.dashUrl, this.selected.hlsUrl);
  }

  public stop(): void {
    stopMovie();
  }

  private selectMovieListItem(item: MovieListItem): void {
    const imageWrapper = document.querySelector<HTMLDivElement>('.detail-image-wrapper')!;
    const img = imageWrapper.querySelector<HTMLImageElement>('img.thumbnail-large')!;
    img.src = item.thumbnailUrl;

    const descriptionWrapper = document.querySelector<HTMLDivElement>('.detail-description-wrapper')!;
    const title = descriptionWrapper.querySelector('h2')!;
    title.innerText = item.title;

    const p = descriptionWrapper.querySelector('p')!;
    p.innerText = item.description;

    forEach(document.querySelectorAll<HTMLImageElement>('img.thumbnail-mini'), (thumbnail) => {
      const id = parseInt(thumbnail.getAttribute('data-movie-list-item-id')!, 10);

      if (id === item.id) {
        thumbnail.classList.add('thumbnail-mini--selected');
      } else {
        thumbnail.classList.remove('thumbnail-mini--selected');
      }
    });

    this.selected = item;
  }

  private applyThumbnailElement(col: HTMLDivElement, item: MovieListItem): void {
    /*
      <div class="col-md-3">
        <div class="embed-responsive embed-responsive-16by9">
          <div class="embed-responsive-item">
            <a href="#">
              <img class="img-rounded thumbnail-mini" src="./image/thumbnails/vod-ell8k.png" alt="..." />
            </a>
          </div>
        </div>
        <h2 class="hidden-md hidden-lg"></h2>
        <p class="hidden-md hidden-lg"></p>
        <button class="btn btn-primary btn-lg">
          <i class="fa fa-play" aria-hidden="true"></i>
          <b class="white-space"></b>
          再生
        </button>
        <p class="thumbnail-mini-margin-bottom"></p>
      </div>
    */

    const a = col.querySelector<HTMLAnchorElement>('a')!;
    /*
    a.addEventListener('mouseenter', (e) => {
      this.selectMovieListItem(item);
    });
    */
    a.addEventListener('click', (e) => {
      e.preventDefault();

      this.selectMovieListItem(item);
    });

    const img = col.querySelector<HTMLImageElement>('img.thumbnail-mini')!;
    img.src = item.thumbnailUrl;
    img.setAttribute('data-movie-list-item-id', item.id.toString());
    img.addEventListener('focus', (e) => {
      this.selectMovieListItem(item);
    });

    const h2 = col.querySelector('h2')!;
    h2.innerText = item.title;

    const p = col.querySelector('p')!;
    p.innerText = item.description;

    const button = col.querySelector('button')!;
    button.addEventListener('click', (e) => {
      e.preventDefault();

      this.selectMovieListItem(item);
      startMovie(item.dashUrl, item.hlsUrl);
    });
  }
}
